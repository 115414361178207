.home {
  padding-top: 50px;
  max-width: 800px;
  margin: 0 auto;
}

.home-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #444;
  padding-bottom: 30px;
  margin-bottom: 30px;
}

@media (max-width: 571px) {
  .home-header {
    flex-direction: column;
    align-items: flex-start;
    padding: 0 0 20px;
    margin-bottom: 15px;
  }

  .home-header-logo {
    max-width: 85px;
    margin-bottom: 20px;
  }

  .home {
    padding: 30px 20px 0;
  }
}

.tels a {
  text-decoration: none;
}
